import { Component, HostListener, Inject, OnInit, ɵComponentType } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    AppWanServiceV2,
    AttributesService,
    AttributesServiceResults,
    EdgeRouterPolicyServiceV2,
    PlatformServiceService,
    ServiceServiceV2,
} from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import {
    ENDPOINT_DIALOG,
    NetworkV2,
    PagedAttributes,
    PlatformService,
    ROUTER_DIALOG,
} from '@netfoundry-ui/shared/model';
import {
    ApiService,
    FeatureService,
    LoggerService,
    NetworkVersionService,
    ValidateService,
} from '@netfoundry-ui/shared/services';
import { FromDatePipe } from '@netfoundry-ui/ui/pipes';
import _ from 'lodash';
import { MetricsModalComponent } from '@netfoundry-ui/feature/metrics-modal';
import { Router } from '@angular/router';
import { DialLogsComponent } from '@netfoundry-ui/feature/dial-logs';

@Component({
    selector: 'app-advanced-platform-service-form',
    templateUrl: './advanced-platform-service-form.component.html',
    styleUrls: ['./advanced-platform-service-form.component.scss'],
})
export class AdvancedPlatformServiceFormComponent implements OnInit {
    public model = new PlatformService();
    invalidWords = ['#all'];
    regions: Location[] = [];
    isComplete = false;
    editId = '';
    processing = false;
    hideHelp = false;
    isInline = false;
    isTerminator = true;
    isClientOpen = true;
    isServerOpen = true;
    isRouterOpen = true;
    networkList = [];
    endpointsList = [];
    edgeRoutersList = [];
    edgeRouterNames = [];
    allEndpoints = [];
    edgeRouterHosts = [];
    endpointAttributes = new PagedAttributes();
    endpointNames = [];
    selectedEndpointAttributes = new PagedAttributes();
    selectedServiceAttributes = new PagedAttributes();
    selectedEdgeRouterAttributes = new PagedAttributes();
    selectedEndpointNames = [];
    edgeRouterAttributes = new PagedAttributes();
    serviceAttributes = new PagedAttributes();
    currentNetwork: NetworkV2;
    errors = {};
    ingressErrorMessage = '';

    isEditing = false;
    canEdit = false;
    isNew = false;
    networkGroupId;
    endpointAttributeError = false;
    ports = [];
    tcp = false;
    udp = false;

    interceptPort = '';
    interceptAddress = '';
    interceptProtocol = '';

    forwardingAllowed = true;
    portForwardingAllowed = true;
    hostForwardingAllowed = true;
    protocolForwardingAllowed = true;

    bulkEdit = false;
    assocEndpointNames = [];
    endpointMap = {};
    assocRouterNames = [];
    routerMap = {};
    showSourceTransparency = false;
    isLoadingAssocRouters: boolean;
    isLoadingAssocEndpoints: boolean;
    isLoadingSvcAttr: boolean;
    svcError: boolean;
    isLoading: any;
    isLoadingEndpointAttr: boolean;
    isLoadingEdgeRouterAttr: boolean;
    edgeRouterError: boolean;
    attributeError = false;
    erAttributeError = false;
    epAttributeError = false;
    clientHostsError = false;
    clientPortsError = false;
    allowedSourceError = false;
    clientIngressAllowedSourceError = false;
    clientProtocolsError = false;
    serverHostError = false;
    serverAllowedProtocolsError = false;
    serverAllowedPortsError = false;
    serverAllowedHostsError = false;

    constructor(
        private logger: LoggerService,
        private dialogRef: MatDialogRef<AdvancedPlatformServiceFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public networkVersionService: NetworkVersionService,
        private serviceService: ServiceServiceV2,
        private edgeRouterPolicyServiceV2: EdgeRouterPolicyServiceV2,
        public appwanServiceV2: AppWanServiceV2,
        private apiService: ApiService,
        private validateService: ValidateService,
        private growlerService: GrowlerService,
        private authorizationService: AuthorizationService,
        private fromDate: FromDatePipe,
        private platformServiceService: PlatformServiceService,
        public featureService: FeatureService,
        public dialogForm: MatDialog,
        private router: Router,
        private attributeService: AttributesService,
        @Inject(ENDPOINT_DIALOG) private endpointDialog: ɵComponentType<any>,
        @Inject(ROUTER_DIALOG) private routerDialog: ɵComponentType<any>
    ) {}

    get previewConfigText(): string {
        const ports = this.ports;
        const addresses = _.get(this.model, 'model.clientIngress.addresses');
        const protocols = _.get(this.model, 'model.clientIngress.protocols');
        return `Allow Ingress traffic ${this.showPortsConfigString ? 'to ports ' + _.join(ports, ', ') : ''}
                ${this.showAddressesConfigString ? 'from ' + _.join(addresses, ', ') : ''}
                ${this.showProtocolsConfigString ? 'via ' + _.join(protocols, ', ') : ''}`;
    }

    get showPortsConfigString(): boolean {
        return !_.isEmpty(this.ports);
    }

    get showAddressesConfigString(): boolean {
        const addresses = this.model.model.clientIngress.addresses;
        return !_.isEmpty(addresses);
    }

    get showProtocolsConfigString(): boolean {
        const protocols = this.model.model.clientIngress.protocols;
        return !_.isEmpty(protocols);
    }

    ngOnInit() {
        this.initModel();
        this.currentNetwork = this.apiService.currentNetwork.getValue();
        if (this.currentNetwork) {
            this.model.networkId = this.currentNetwork.id;
            this.networkGroupId = this.currentNetwork.networkGroupId;
            this.isLoading = true;
            this.checkFeatureSupport();
            this.initializeEndpointSelector();
            this.initializeEdgeRouterSelector();
            this.initializeServiceAttributeSelector();

            if (this.model.id) {
                this.findAssociatedEntities();
            }
        }

        if (this.bulkEdit) {
            this.validate();
        }
    }

    addEndpointsAttributes(newAttribute) {
        const success = this.attributeService.addAttributes(newAttribute, [], this.selectedEndpointAttributes);
        this.model.endpointAttributes = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
        this.validate();
        if (success) this.selectedEndpointAttributes = success as PagedAttributes;
        else this.endpointAttributeError = true;
    }

    addEdgeRouterAttribute(newAttribute) {
        const success = this.attributeService.addAttributes(newAttribute, [], this.selectedEdgeRouterAttributes);
        this.model.edgeRouterAttributes = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        this.validate();
        if (success) this.selectedEdgeRouterAttributes = success as PagedAttributes;
        else this.edgeRouterError = true;
    }

    addServiceAttribute(newAttribute) {
        const success = this.attributeService.addAttributes(
            newAttribute,
            this.invalidWords,
            this.selectedServiceAttributes
        );
        this.model.attributes = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        this.validate();
        if (success) this.selectedServiceAttributes = success as PagedAttributes;
        else this.svcError = true;
    }

    removeServiceAttribute(oldAttribute) {
        this.selectedServiceAttributes = this.attributeService.removeAttribute(
            oldAttribute,
            this.selectedServiceAttributes
        );
        this.model.attributes = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        this.validate();
    }

    removeEndpointAttribute(oldAttribute) {
        this.selectedEndpointAttributes = this.attributeService.removeAttribute(
            oldAttribute,
            this.selectedEndpointAttributes
        );
        this.model.endpointAttributes = Array.from(this.selectedEndpointAttributes.mappedAtrributes.keys());
        this.validate();
    }

    removeEdgeRouterAttribute(oldAttribute) {
        this.selectedEdgeRouterAttributes = this.attributeService.removeAttribute(
            oldAttribute,
            this.selectedEdgeRouterAttributes
        );
        this.model.edgeRouterAttributes = Array.from(this.selectedEdgeRouterAttributes.mappedAtrributes.keys());
        this.validate();
    }

    async checkFeatureSupport() {
        let isSupported = false;
        try {
            const networkFeaturesResult = await this.networkVersionService
                .getNetworkFeatures(this.currentNetwork)
                .toPromise();
            const platformFeatures = _.get(networkFeaturesResult, '_embedded.network-versions[0].platformFeatures', []);
            isSupported = _.some(
                platformFeatures,
                (feature: any) => feature.displayName === 'service.advanced-tunneler-to-endpoint.source-ip-transparency'
            );
        } catch (e) {
            isSupported = false;
        }
        this.showSourceTransparency = isSupported;
    }

    toggleClient() {
        this.isClientOpen = !this.isClientOpen;
    }

    toggleServer() {
        this.isServerOpen = !this.isServerOpen;
    }

    toggleRouter() {
        this.isRouterOpen = !this.isRouterOpen;
    }

    sdkTunneler() {
        if (this.model.modelType === 'TunnelerToSdk') {
            this.model.modelType = 'TunnelerToEndpoint';
        } else {
            this.model.modelType = 'TunnelerToSdk';
        }
        this.typeSelection();
    }

    onKeyDown(event: KeyboardEvent) {
        if (event.key === ' ') {
            event.preventDefault();
            const element = event.target as HTMLElement;
            element.blur();
            element.focus();
        }
    }

    processPortArray() {
        this.logger.info(JSON.stringify(this.ports));
        this.model.model.clientIngress.ports = [];
        for (const port of this.ports) {
            if (port.includes('-')) {
                const portRange = port.split('-');
                this.model.model.clientIngress.ports.push({ low: portRange[0].trim(), high: portRange[1].trim() });
            } else {
                this.model.model.clientIngress.ports.push({ low: port.trim(), high: port.trim() });
            }
        }
        this.logger.info(this.model.model.clientIngress.ports);
    }

    trimWhitespaceAddresses() {
        this.model.model.clientIngress.addresses = this.model.model.clientIngress.addresses.map(_.trim);
    }

    hide(escaped?) {
        this.dialogRef.close({ newClient: this.model, escaped });
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
        this.hide(true);
    }

    get disableEncryptionToggle() {
        return (
            this.isEditing ||
            (this.model.modelType === 'AdvancedTunnelerToEndpoint' &&
                !this.featureService.canDisabledServiceEncryption(this.currentNetwork))
        );
    }

    toggleEncryption() {
        // For now encryption is always required for advanced platform services
        if (this.disableEncryptionToggle) {
            return;
        }
        if (this.model.modelType === 'TunnelerToEdgeRouter') {
            for (const edgeRouterHost of this.model.model.edgeRouterHosts) {
                if (edgeRouterHost.serverEgress.protocol === 'udp') {
                    this.model.encryptionRequired = false;
                    return;
                }
            }
        }

        this.model.encryptionRequired = !this.model.encryptionRequired;
    }

    toggleTransparency() {
        const sourceIpConfigType = _.get(this.model, 'model.sourceIpConfigType');
        const sourceIpConfig = _.get(this.model, 'model.sourceIpConfig');
        if (sourceIpConfigType && sourceIpConfig && this.model.model.sourceIpConfigType === 'Transparency') {
            delete this.model.model.sourceIpConfigType;
            delete this.model.model.sourceIpConfig;
        } else {
            const config = {
                allowedSourceAddresses: [],
            };
            _.set(this.model, 'model.sourceIpConfigType', 'Transparency');
            _.set(this.model, 'model.sourceIpConfig', config);
        }
    }

    processForwardingConfig() {
        // Handle Protocols
        if (this.protocolForwardingAllowed) {
            this.model.model.serverEgress.forwardProtocol = true;
            this.model.model.serverEgress.allowedProtocols = this.model.model.clientIngress.protocols;
            delete this.model.model.serverEgress.protocol;
        } else {
            delete this.model.model.serverEgress.forwardProtocol;
            delete this.model.model.serverEgress.allowedProtocols;
        }

        // Handle Hosts
        if (this.hostForwardingAllowed) {
            this.model.model.serverEgress.forwardHost = true;
            this.model.model.serverEgress.allowedHosts = this.model.model.clientIngress.addresses;
            delete this.model.model.serverEgress.host;
        } else {
            delete this.model.model.serverEgress.forwardHost;
            delete this.model.model.serverEgress.allowedHosts;
        }

        // Handle Ports
        if (this.portForwardingAllowed) {
            this.model.model.serverEgress.forwardPort = true;
            this.model.model.serverEgress.allowedPortRanges = this.model.model.clientIngress.ports;
            delete this.model.model.serverEgress.port;
        } else {
            delete this.model.model.serverEgress.forwardPort;
            delete this.model.model.serverEgress.allowedPortRanges;
        }
    }

    async save() {
        if (await this.validate()) {
            this.processing = true;

            if (this.bulkEdit) {
                this.dialogRef.close();
            } else if (this.model.id) {
                _.unset(this.model, 'configs');
                this.model.configIdByConfigTypeId = null;
                this.model.updatedAt = null;
                this.model.createdAt = null;
                this.model.createdBy = null;
                this.model.ownerIdentityId = null;
                this.platformServiceService.patchResource(this.model).subscribe(
                    (data) => {
                        this.logger.info('Update service response: ', data);
                        this.processing = false;
                        this.dialogRef.close({ updatedService: this.model });
                        this.growlerService.show(
                            new GrowlerData(
                                'success',
                                'Success',
                                'Service update successful',
                                'Service update process started successfully.<a href="' +
                                    this.router.createUrlTree(['/process-executions']).toString() +
                                    '">Click here to find out more.</a>'
                            )
                        );
                    },
                    (httpErrorResponse) => {
                        this.processing = false;
                        this.logger.error('Error from service update', httpErrorResponse);
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Service update request failed. ',
                                httpErrorResponse.error.errors[0]
                            )
                        );
                    }
                );
            } else {
                this.platformServiceService.createResource({ body: this.model }).subscribe(
                    (data) => {
                        this.logger.info('Create service response: ', data);
                        this.growlerService.show(
                            new GrowlerData(
                                'success',
                                'Success',
                                'Creation Complete',
                                'Service creation process started successfully.<a href="' +
                                    this.router.createUrlTree(['/process-executions']).toString() +
                                    '">Click here to find out more.</a>'
                            )
                        );
                        this.processing = false;
                        this.dialogRef.close();
                    },
                    (httpErrorResponse) => {
                        this.processing = false;
                        this.logger.error('Error from service creation', httpErrorResponse);
                        this.growlerService.show(
                            new GrowlerData(
                                'error',
                                'Service creation request failed. ',
                                httpErrorResponse.error.errors[0]
                            )
                        );
                    }
                );
            }
        }
    }

    async validate() {
        this.errors = {};
        this.attributeError = false;
        this.erAttributeError = false;
        this.epAttributeError = false;
        this.processPortArray();
        this.trimWhitespaceAddresses();
        this.processForwardingConfig();
        if(_.isEmpty(this.model.model.clientIngress?.allowedSourceAddresses)) {
            delete this.model.model.clientIngress.allowedSourceAddresses
        }

        this.model.attributes = Array.from(this.selectedServiceAttributes.mappedAtrributes.keys());
        this.model.model.bindEndpointAttributes = [];
        for (const attribute of this.selectedEndpointAttributes.mappedAtrributes.keys()) {
            this.model.model.bindEndpointAttributes.push(attribute);
        }
        this.model.model.edgeRouterAttributes = [];
        for (const attribute of this.selectedEdgeRouterAttributes.mappedAtrributes.keys()) {
            this.model.model.edgeRouterAttributes.push(attribute);
        }

        if (!this.model.id) {
            const errors = await this.platformServiceService.validateCreate(this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.attributes')) {
                this.attributeError = true;
                this.errors['attributes'] = _.find(errors, (e) => e.path === '$.attributes').message;
            }
            if (_.find(errors, (e) => e.path.includes('edgeRouterAttributes'))) {
                this.erAttributeError = true;
                this.errors['edgeRouterAttributes'] = _.find(errors, (e) =>
                    e.path.includes('edgeRouterAttributes')
                ).message;
            }
            if (_.find(errors, (e) => e.path.includes('bindEndpointAttributes'))) {
                this.epAttributeError = true;
                this.errors['bindEndpointAttributes'] = _.find(errors, (e) =>
                    e.path.includes('bindEndpointAttributes')
                ).message;
            }
            // if (_.find(errors, (e) => e.path.includes('allowedSourceAddresses'))) {
            //     this.allowedSourceError = true;
            //     this.errors['allowedSourceAddresses'] = _.find(errors, (e) =>
            //         e.path.includes('allowedSourceAddresses')
            //     ).message;
            // }

            if (_.find(errors, (e) => e.path.includes('addresses'))) {
                this.clientHostsError = true;
                this.errors['clientHostsError'] = _.find(errors, (e) => e.path.includes('addresses')).message;
            }
            if (_.find(errors, (e) => e.path.includes('ports'))) {
                this.errors['clientPortsError'] = _.find(errors, (e) => e.path.includes('ports')).message;
            }
            if (_.find(errors, (e) => e.path === '$.model.clientIngress.protocols')) {
                this.clientProtocolsError = true;
                this.errors['clientProtocolsError'] = _.find(
                    errors,
                    (e) => e.path === '$.model.clientIngress.protocols'
                ).message;
            }
            if (_.find(errors, (e) => e.path.includes('clientIngress.allowedSourceAddresses'))) {
                this.allowedSourceError = true;
                this.errors['clientIngressAllowedSourceError'] = _.find(errors, (e) =>
                    e.path.includes('clientIngress.allowedSourceAddresses')
                ).message;
            }
            if (_.find(errors, (e) => e.path === '$.model.serverEgress.allowedProtocols')) {
                this.serverAllowedProtocolsError = true;
                this.errors['serverAllowedProtocolsError'] = _.find(
                    errors,
                    (e) => e.path === '$.model.serverEgress.allowedProtocols'
                ).message;
            }
            if (_.find(errors, (e) => e.path.includes('PortRanges'))) {
                this.errors['serverAllowedPortsError'] = _.find(errors, (e) => e.path.includes('PortRanges')).message;
            }
            if (_.find(errors, (e) => e.path.includes('allowedHosts'))) {
                this.serverAllowedHostsError = true;
                this.errors['serverAllowedHostsError'] = _.find(errors, (e) => e.path.includes('allowedHosts')).message;
            }
            if (_.find(errors, (e) => e.path.includes('serverEgress.host'))) {
                this.serverHostError = true;
                this.errors['serverHostError'] = _.find(errors, (e) => e.path.includes('serverEgress.host')).message;
            }
            return errors.length === 0;
        } else {
            _.unset(this.model, 'configs');
            this.model.configIdByConfigTypeId = null;
            this.model.updatedAt = null;
            this.model.createdAt = null;
            this.model.createdBy = null;
            const errors = await this.platformServiceService.validateUpdate(this.model.id, this.model);
            if (!_.isArray(errors)) {
                return false;
            }
            if (_.find(errors, (e) => e.path === '$.name')) {
                this.errors['name'] = _.find(errors, (e) => e.path === '$.name').message;
            }
            if (_.find(errors, (e) => e.path === '$.attributes')) {
                this.attributeError = true;
                this.errors['attributes'] = _.find(errors, (e) => e.path === '$.attributes').message;
            }
            if (_.find(errors, (e) => e.path.includes('edgeRouterAttributes'))) {
                this.erAttributeError = true;
                this.errors['edgeRouterAttributes'] = _.find(errors, (e) =>
                    e.path.includes('edgeRouterAttributes')
                ).message;
            }
            if (_.find(errors, (e) => e.path.includes('bindEndpointAttributes'))) {
                this.epAttributeError = true;
                this.errors['bindEndpointAttributes'] = _.find(errors, (e) =>
                    e.path.includes('bindEndpointAttributes')
                ).message;
            }
            // if (_.find(errors, (e) => e.path.includes('allowedSourceAddresses'))) {
            //     this.allowedSourceError = true;
            //     this.errors['allowedSourceAddresses'] = _.find(errors, (e) =>
            //         e.path.includes('allowedSourceAddresses')
            //     ).message;
            // }            
            if (_.find(errors, (e) => e.path.includes('clientIngress.allowedSourceAddresses'))) {
                this.clientIngressAllowedSourceError = true;
                this.errors['clientIngressAllowedSourceError'] = _.find(errors, (e) =>
                    e.path.includes('clientIngress.allowedSourceAddresses')
                ).message;
            }
            if (_.find(errors, (e) => e.path.includes('addresses'))) {
                this.clientHostsError = true;
                this.errors['clientHostsError'] = _.find(errors, (e) => e.path.includes('addresses')).message;
            }
            if (_.find(errors, (e) => e.path.includes('ports'))) {
                this.errors['clientPortsError'] = _.find(errors, (e) => e.path.includes('ports')).message;
            }
            if (_.find(errors, (e) => e.path === '$.model.clientIngress.protocols')) {
                this.clientProtocolsError = true;
                this.errors['clientProtocolsError'] = _.find(
                    errors,
                    (e) => e.path === '$.model.clientIngress.protocols'
                ).message;
            }
            if (_.find(errors, (e) => e.path === '$.model.serverEgress.allowedProtocols')) {
                this.serverAllowedProtocolsError = true;
                this.errors['serverAllowedProtocolsError'] = _.find(
                    errors,
                    (e) => e.path === '$.model.serverEgress.allowedProtocols'
                ).message;
            }
            if (_.find(errors, (e) => e.path.includes('PortRanges'))) {
                this.errors['serverAllowedPortsError'] = _.find(errors, (e) => e.path.includes('PortRanges')).message;
            }
            if (_.find(errors, (e) => e.path.includes('allowedHosts'))) {
                this.serverAllowedHostsError = true;
                this.errors['serverAllowedHostsError'] = _.find(errors, (e) => e.path.includes('allowedHosts')).message;
            }
            if (_.find(errors, (e) => e.path.includes('serverEgress.host'))) {
                this.serverHostError = true;
                this.errors['serverHostError'] = _.find(errors, (e) => e.path.includes('serverEgress.host')).message;
            }
            return errors.length === 0;
        }
    }

    getMetrics() {
        this.dialogForm.open(MetricsModalComponent, {
            data: {
                resourceType: 'service',
                model: this.model,
                networkGroupId: this.networkGroupId,
                networkId: this.currentNetwork.id,
            },
            height: '800px',
            width: '1200px',
            autoFocus: false,
        });
    }

    getDialLogs() {
        this.dialogForm.open(DialLogsComponent, {
            data: {
                resourceType: 'service',
                model: this.model,
                networkGroupId: this.networkGroupId,
                networkId: this.currentNetwork.id,
            },
            height: '800px',
            width: '1000px',
            autoFocus: false,
        });
    }

    protocolSelection($event) {
        if ($event.currentTarget.value === 'udp' && this.model.modelType === 'TunnelerToEdgeRouter') {
            this.model.encryptionRequired = false;
            return;
        }

        if (this.model.model.edgeRouterHosts && this.model.model.edgeRouterHosts.length > 0) {
            for (const edgeRouterHost of this.model.model.edgeRouterHosts) {
                if (this.model.modelType === 'TunnelerToEdgeRouter') {
                    if (
                        edgeRouterHost.serverEgress.protocol === 'udp' &&
                        this.model.modelType === 'TunnelerToEdgeRouter'
                    ) {
                        return;
                    }
                }
            }
        }

        this.model.encryptionRequired = true;
    }

    protocolCheckboxSelection() {
        this.model.model.clientIngress.protocols = [];
        if (this.udp) {
            this.model.model.clientIngress.protocols.push('udp');
        }
        if (this.tcp) {
            this.model.model.clientIngress.protocols.push('tcp');
        }
        this.validate();
    }

    dialInterceptProtocolToggle() {
        this.model.model.serverEgress.dialInterceptProtocol = !this.model.model.serverEgress.dialInterceptProtocol;
        if (this.model.model.serverEgress.dialInterceptProtocol) {
            _.unset(this.model, 'model.serverEgress.protocol');
        }
    }

    forwardingAllowedToggle() {
        this.forwardingAllowed = !this.forwardingAllowed;
    }

    portForwardingAllowedToggle() {
        this.portForwardingAllowed = !this.portForwardingAllowed;
    }

    hostForwardingAllowedToggle() {
        this.hostForwardingAllowed = !this.hostForwardingAllowed;
    }

    protocolForwardingAllowedToggle() {
        this.protocolForwardingAllowed = !this.protocolForwardingAllowed;
    }

    dialInterceptPortToggle() {
        this.model.model.serverEgress.dialInterceptPort = !this.model.model.serverEgress.dialInterceptPort;
        if (this.model.model.serverEgress.dialInterceptPort) {
            _.unset(this.model, 'model.serverEgress.port');
        }
    }

    typeSelection() {
        if (this.model.modelType === 'TunnelerToEdgeRouter') {
            this.model.model = {
                edgeRouterHosts: [
                    {
                        edgeRouterId: '',
                        serverEgress: {},
                    },
                ],
                clientIngress: this.model.model.clientIngress,
            };
        } else {
            this.model.model = {
                serverEgress: {},
                clientIngress: this.model.model.clientIngress,
                edgeRouterAttributes: [],
            };
        }
    }

    addHAEdgeRouter() {
        if (this.model.model.edgeRouterHosts.length < 4) {
            const lastEdgeRouterHost = this.model.model.edgeRouterHosts[this.model.model.edgeRouterHosts.length - 1];
            this.model.model.edgeRouterHosts.push({
                edgeRouterId: '',
                serverEgress: {
                    protocol: lastEdgeRouterHost.serverEgress.protocol,
                    host: lastEdgeRouterHost.serverEgress.host,
                    port: lastEdgeRouterHost.serverEgress.port,
                },
            });
        }
    }

    deleteHAEdgeRouter(index) {
        if (this.model.model.edgeRouterHosts.length > 1) {
            this.model.model.edgeRouterHosts.splice(index, 1);
        }
    }

    endpointSelected(name: string) {
        const service = this.endpointMap[name];
        const dialogRef = this.dialogForm.open(this.endpointDialog, {
            data: { model: service, inline: true },
            minHeight: '93%',
            minWidth: '100%',
            height: '93%',
            width: '100%',
        });
        dialogRef.afterClosed().subscribe(() => {});
    }

    routerSelected(name: string) {
        const router = this.routerMap[name];
        const dialogRef = this.dialogForm.open(this.routerDialog, {
            data: { model: router, inline: true },
            minHeight: '93%',
            minWidth: '100%',
            height: '93%',
            width: '100%',
        });
        dialogRef.afterClosed().subscribe(() => {});
    }

    findAssociatedEntities() {
        this.isLoadingAssocRouters = true;

        this.serviceService
            .findAssociatedRouters(this.model.id)
            .then((recs) => {
                this.assocRouterNames = recs.map((s) => s.name);
                recs.forEach((rec) => {
                    this.routerMap[rec.name] = rec;
                });
            })
            .finally(() => {
                this.isLoadingAssocRouters = false;
            });
        this.isLoadingAssocEndpoints = false;
        this.serviceService
            .findAssociatedEndpoints(this.model.id)
            .then((recs) => {
                this.assocEndpointNames = recs.map((s) => s.name);
                recs.forEach((rec) => {
                    this.endpointMap[rec.name] = rec;
                });
            })
            .finally(() => {
                this.isLoadingAssocEndpoints = false;
            });
    }

    private initializeServiceAttributeSelector() {
        this.isLoadingSvcAttr = true;
        this.getSelectedServiceAttributes();
        this.svcError = false;
        this.attributeService
            .getServiceAttributes(this.currentNetwork, true)
            .then((results: AttributesServiceResults) => {
                this.serviceAttributes = results.groupAttributes;
            })
            .catch((err) => {
                this.svcError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingSvcAttr = false;
                this.isLoading = this.isLoadingEndpointAttr || this.isLoadingSvcAttr || this.isLoadingEdgeRouterAttr;
            });
    }

    private getSelectedServiceAttributes() {
        const selectedServices = new PagedAttributes();
        this.model.attributes.forEach((att) => {
            selectedServices.mappedAtrributes.set(att, {
                name: att,
                isGroup: att.charAt(0) === '#',
                isNamed: att.charAt(0) === '@',
            });
        });
        this.selectedServiceAttributes = selectedServices;
    }

    private initializeEdgeRouterSelector() {
        this.isLoadingEdgeRouterAttr = true;
        this.getSelectedEdgeRouters();
        this.edgeRouterError = false;
        this.attributeService
            .getEdgeRouterAttributes(this.currentNetwork, false)
            .then((results: AttributesServiceResults) => {
                const collector = results.namedAttributes;
                for (const sa of results.groupAttributes.mappedAtrributes.values()) {
                    collector.mappedAtrributes.set(sa.name, sa);
                }
                this.edgeRouterAttributes = collector;
            })
            .catch((err) => {
                this.edgeRouterError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingEdgeRouterAttr = false;
                this.isLoading = this.isLoadingEndpointAttr || this.isLoadingSvcAttr || this.isLoadingEdgeRouterAttr;
            });
    }

    private getSelectedEdgeRouters() {
        const selectedEdgeRouters = new PagedAttributes();
        this.model.model.edgeRouterAttributes.forEach((att) => {
            selectedEdgeRouters.mappedAtrributes.set(att, {
                name: att,
                isGroup: att.charAt(0) === '#',
                isNamed: att.charAt(0) === '@',
            });
        });
        this.selectedEdgeRouterAttributes = selectedEdgeRouters;
    }

    private initializeEndpointSelector() {
        this.isLoadingEndpointAttr = true;
        this.getSelectedEndpoints();
        this.endpointAttributeError = false;
        this.attributeService
            .getEndpointAttributes(this.currentNetwork, false)
            .then((results: AttributesServiceResults) => {
                const collector = results.namedAttributes;
                for (const sa of results.groupAttributes.mappedAtrributes.values()) {
                    collector.mappedAtrributes.set(sa.name, sa);
                }
                this.endpointAttributes = collector;
            })
            .catch((err) => {
                this.endpointAttributeError = true;
                this.logger.error(err);
            })
            .finally(() => {
                this.isLoadingEndpointAttr = false;
                this.isLoading = this.isLoadingEndpointAttr || this.isLoadingSvcAttr || this.isLoadingEdgeRouterAttr;
            });
    }

    private getSelectedEndpoints() {
        const selectedEndpoints = new PagedAttributes();
        this.model.model.bindEndpointAttributes.forEach((att) => {
            selectedEndpoints.mappedAtrributes.set(att, {
                name: att,
                isGroup: att.charAt(0) === '#',
                isNamed: att.charAt(0) === '@',
            });
        });
        this.selectedEndpointAttributes = selectedEndpoints;
    }

    private initModel() {
        this.model.encryptionRequired = true;
        this.model.modelType = 'AdvancedTunnelerToEndpoint';
        if (this.data.model) {
            this.model = this.data.model;
            this.bulkEdit = this.data.bulkEdit;
            if (_.get(this.data, 'model.model.serverEgress.protocol')) {
                this.protocolForwardingAllowed = false;
            }
            if (_.get(this.data, 'model.model.serverEgress.host')) {
                this.hostForwardingAllowed = false;
            }
            if (_.get(this.data, 'model.model.serverEgress.port')) {
                this.portForwardingAllowed = false;
            }
            if (!this.model.model) {
                this.model.model = {
                    serverEgress: {},
                    clientIngress: {
                        addresses: [],
                        allowedSourceAddresses: []
                    },
                };
            }

            if (this.model.model.clientIngress.ports) {
                this.ports = [];
                for (const port of this.model.model.clientIngress.ports) {
                    if (port.low === port.high) {
                        this.ports.push(port.low.toString());
                    } else {
                        this.ports.push(port.low + '-' + port.high);
                    }
                }
            }

            if (this.model.model.clientIngress.protocols) {
                this.tcp = this.model.model.clientIngress.protocols.includes('tcp');
                this.udp = this.model.model.clientIngress.protocols.includes('udp');
            }

            this.canEdit = this.authorizationService.canUpdateService(this.model.id);
            this.isEditing = !this.bulkEdit;
        } else {
            this.isNew = true;
        }

        if (!this.model.model) {
            this.model.model = {
                serverEgress: {},
                clientIngress: {
                    addresses: [],
                    allowedSourceAddresses: []
                },
            };
        }

        if (!this.model.model.edgeRouterAttributes) {
            this.model.model.edgeRouterAttributes = [];
        }
        if (!this.model.model.bindEndpointAttributes) {
            this.model.model.bindEndpointAttributes = [];
        }
        if (!this.model.model.bindEndpoints) {
            this.model.model.bindEndpoints = [];
        }

        if (!this.model.attributes) {
            this.model.attributes = [];
        }

        if (!this.model.name) {
            this.model.name = '';
        }
    }
}
